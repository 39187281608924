

































import Vue from 'vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'

export default Vue.extend({
  name: 'ResetPassword',
  components: { SubmitButton },
  data() {
    return {
      password: '',
      passwordConfirmation: '',
      showPassword: false,
      showPasswordConfirmation: false,
      token: this.$route.query.token,
      email: this.$route.query.email,
    }
  },
  computed: {
    passwordFieldType(): string {
      return this.showPassword ? 'text' : 'password'
    },
    passwordFieldIcon(): string {
      return this.showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
    },
    passwordConfirmationFieldType(): string {
      return this.showPasswordConfirmation ? 'text' : 'password'
    },
    passwordConfirmationFieldIcon(): string {
      return this.showPasswordConfirmation ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
    },
  },
  methods: {
    toggleShowPassword(): void {
      this.showPassword = !this.showPassword
    },
    toggleShowPasswordConfirmation(): void {
      this.showPasswordConfirmation = !this.showPasswordConfirmation
    },
    submit(): void {
      // TODO: Implement API call
      console.log('Check if token is valid token', this.password, this.passwordConfirmation)
    },
  },
  beforeMount() {
    console.log('Check if token is valid token', this.token)
  },
})
